/* You can add global styles to this file, and also import other style files */
@import "bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

*{
  padding: 0px;
  margin: 0px;
}


.flex-centrar{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.ausencia {
  color: #ED701C;
  font-weight: bold;
  font-family: Montserrat;
  font-size: 20px;
}
.naranja{
  color: #ed701c;
}

.lato {
  font-family: 'Lato';
}

.montserrat {
  font-family: 'Montserrat';
}




/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("assets/images/img/font.woff2") format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}
.bold-lato{

  font-weight: bold;
}
.azul{
  color: #0F2335;
}
.derecha-texto {
  text-align: right;
}

.linea-naranja {
  height: 1px;
  color: #ED701C;
  background-color: #ED701C;
  width: 200px;
}



@media(max-width:1056px) {
  .linea-naranja {
    width: 160px;
  }
}

@media(max-width:670px) {
  .linea-naranja {
    width: 140px;
  }
}

@media(max-width:355px) {
  .linea-naranja {
    width: 120px;
  }
}

.redes {
  position: fixed;
  top: 90%;
  left: 90%;
  overflow: hidden;
  width: 100%;
  z-index: 999;
}

.imagenesRedes {
  width: 70px;
}

.colorMorado {
  color: #ff00c9;
}

.titulos-organizador {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  color: #0F2335;
}

.titulo-eventos {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: #0F2335;
}

@media(max-width:1230px) {
  .titulos-organizador {
    font-size: 2.5vw;
    line-height: 2.5vw;
  }
}

@media(max-width:900px) {
  .titulos-organizador {
    font-size: 3vw;
    line-height: 3vw;
  }
}

@media(max-width:700px) {
  .titulos-organizador {
    font-size: 3.5vw;
    line-height: 3.5vw;
  }
}

@media(max-width:650px) {
  .titulos-organizador {
    font-size: 4vw;
    line-height: 4vw;
  }
}

@media(max-width:550px) {
  .titulos-organizador {
    font-size: 5vw;
    line-height: 5vw;
  }
}

@media(max-width:450px) {
  .titulos-organizador {
    font-size: 6vw;
    line-height: 6vw;
  }
}

@media(max-width:901px) {
  .redes {

    top: 85%;
    left: 85%;

  }
}

@media(max-width:551px) {
  .redes {

    top: 85%;
    left: 80%;

  }
}

@media(max-width:427px) {
  .redes {

    top: 85%;
    left: 75%;

  }
}

.barra {
  background-color: rgba(16, 36, 56, 1);
  /* background-color:black; */

  width: 100%;
  height: 90px;
  z-index: 20;

}

.botonBarra {
  color: white;
  background: linear-gradient(#F9B84A, #ed701c);
}


.textoFooter {

  color: white;

  text-align: center;
  background-color: rgba(16, 36, 56, 1);
}

.login {
  text-align: center;

}

.error {
  color: #ed701c;
  text-align: center;
}





.img11 {
  background-image: url(../src/assets/images/img/Concert1.webp);
  width: 100%;
  height: 7vw;
  align-items: center;
  z-index: 0;
  background-repeat: no-repeat;
  /* Evita que la imagen se repita */
  background-size: 100% auto;
}


.filtroNaranja {

  width: 50%;
  height: 10vw;
  background: linear-gradient(90deg, rgba(237, 112, 28, 0.9) 10.19%, rgba(249, 184, 74, 0.81) 97.62%);
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 0px 48px 48px 0px;
  display: flex;
  align-items: center;
}

.filtroNaranja1 {

  width: 50%;
  height: 7vw;
  background: linear-gradient(90deg, rgba(237, 112, 28, 0.9) 10.19%, rgba(249, 184, 74, 0.81) 97.62%);
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 0px 48px 48px 0px;
  display: flex;
  align-items: center;
}

.nombre {

  font-size: 2vw;
  text-align: left;
  color: #FFFFFF;
  font-family: Montserrat;
  font-weight: bold;
  z-index: 3;
  padding-left: 5%;
}

.nombre1 {

  font-size: 2vw;
  text-align: left;
  color: #FFFFFF;
  font-family: Montserrat;
  font-weight: bold;
  z-index: 3;
  padding-left: 5%;
}

@media(max-width:1130px) {

  .img1,
  .filtroNaranja {
    height: 11vw;
  }
}

@media(max-width:850px) {

  .img1,
  .filtroNaranja {
    height: 13vw;
  }

  .filtroNaranja {
    width: 70%;
  }

  .nombre {
    font-size: 2.5vw;
  }
}

@media(max-width:715px) {

  .img1,
  .filtroNaranja {
    height: 110px;
  }

  .nombre {
    font-size: 3vw;

  }
}

@media(max-width:550px) {

  .img1,
  .filtroNaranja {
    height: 90px;
  }

  .nombre {
    font-size: 3.8vw;

  }
}

@media(max-width:450px) {

  .img1,
  .filtroNaranja {
    height: 70px;
  }

}

.menu-pequeño {
  list-style: none;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  font-size: 17px;
  font-family: Lato;
  color: #0F2335;
}

.menu-pequeño li {
  padding: 10px;
  transition: 500ms all;
  cursor: pointer;
}

.menu-pequeño li:hover {

  color: #ED701C;

}

.menu-pequeño-lista-activo {
  font-weight: bold;
  background-color: #ED701C;
  color: #F7DBA7;
  border-radius: 7px;
  transition: 1000ms all;
}

@media(max-width:735px) {
  .menu-pequeño {
    font-size: 15px;
  }

  .menu-pequeño li {
    padding: 8px;
  }
}

@media(max-width:466px) {
  .menu-pequeño {
    font-size: 14px;
  }

  .menu-pequeño li {
    padding: 6px;
  }
}


.menu-grande-lista-activo {
  font-weight: bold;
  background-color: #EAEAEA;
  color: #0F2335;
  border-radius: 12px;
  transition: 800ms all;

}

.menu-pequeño-lista-activo:hover {
  color: #F7DBA7 !important;
}


.boton-redondo {
  background-color: #0F2335;
  border: 2px solid #ED701C;
  color: #F7DBA7;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 10px;
  border-radius: 999px;
  width: 50px !important;
  height: 50px !important;
  transition: 1000ms all;
}

.boton-redondo:not(:disabled):hover {
  background-color: #ED701C;
  border: 2px solid #0F2335;
}

.boton-redondo:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

hr{
  opacity: 1!important;
}



#prueba {
  background-image: url("assets/images/img/concierto.jpg");
  text-align: left;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

}


#prueba:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(236, 158, 40, 0.87), rgba(255, 80, 28, 0.87));

}

#prueba h1 {

  color: white;
  position: relative;


}

#prueba small {
  font-size: 12px;
  color: white;
  position: relative
}

.titulos2 {
  background-color: #ed701c;
  color: white;
  text-align: center;
}

#prueba2 {

  background-image: url("assets/images/img/fondo.jpg");
  padding: 70px;
  text-align: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

}


#prueba2:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

#prueba2 h1 {

  color: white;
  position: relative
}

#prueba2 small {
  font-size: 12px;
  color: white;
  position: relative
}

.descripciones {

  color: white;
  background-color: black;

}




#contenido {




  color: white;
  margin-top: 100px;

}


@media(max-width:10000px) and (min-width:1200px) {

  #prueba6 {
    padding: 185px;
  }

  #prueba6 h1 {
    font-size: 45px;

  }
}


@media(max-width:1199px) and (min-width:992px) {

  #prueba6 {
    padding: 155px;
  }

  #prueba6 h1 {
    font-size: 40px;

  }
}

@media(max-width:991px) and (min-width:768px) {

  #prueba6 {
    padding: 165px;
  }

  #prueba6 h1 {
    font-size: 40px;

  }
}

@media(max-width:767px) and (min-width:601px) {

  #prueba6 {
    padding: 120px;
  }

  #prueba6 h1 {
    font-size: 40px;

  }
}


@media(max-width:600px) and (min-width:0px) {

  #prueba6 {
    padding: 100px;
  }

  #prueba6 h1 {
    font-size: 25px;

  }
}



@media(max-width:10000px) and (min-width:1200px) {

  #prueba {
    padding: 20px;
  }

  #prueba h1 {
    font-size: 32px;

  }
}




@media(max-width:1199px) and (min-width:992px) {

  #prueba {
    padding: 30px;
  }

  #prueba h1 {
    font-size: 30px;

  }
}

.Banner {
  overflow: hidden;
}

.titulos {


  color: dimgray;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #ed701c;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-overlay{
  position: fixed; /* Fija el overlay en relación a la ventana */
  top: 0;          /* Ajusta la posición al borde superior */
  left: 0;         /* Ajusta la posición al borde izquierdo */
  width: 100%;     /* Ocupa todo el ancho de la ventana */
  height: 100%;    /* Ocupa toda la altura de la ventana */
  background: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  display: flex;   /* Usa flexbox para centrar el contenido */
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Centra horizontalmente */
  z-index: 9999;
}

.linea {
  border-top: 1px solid #ed701c;
  height: 2px;
  max-width: 200px;
  padding: 0;
  margin: 20px auto 0 auto;
}



.contenido {
  position: relative;
  min-height: 85vh;
  padding-top: 60px;
  transition: padding-top 0.6s ease
}

body.menu-open .contenido {
  padding-top: 0; /* Elimina el hueco */
}




.ng-valid:not(form) {
  border-left: 5px solid green;
}

.contenedor8:hover .action_button {

  background: white !important;
  color: #0F2335 !important;
  border: 1px solid #0F2335 !important;


}








.action_button {
  text-decoration: none;
  color: #F7DBA7;
  font-weight: bold;

  background: #0F2335;
  border: 1px solid #0F2335;
  padding: 13px 26px;
  font-size: 13px;
  font-family: Montserrat;
  border-radius: 6px;
  transition: all 500ms;
}

.action_button2 {
  text-decoration: none;
  color: #F7DBA7;
  font-weight: bold;
  background: #ED701C;
  padding: 13px -25px;
  font-size: 17px;
  font-family: Montserrat;
  border-radius: 6px;
  transition: all 500ms;
  width: 150px;
  height: 33px;
  border: 1px solid #ED701C;
  margin-bottom: 15px;
}

.action_button:hover {
  background: white !important;
  color: #0F2335;
  border: 1px solid #0F2335;
}


@media(max-width:700px) {
  .action_button {
    font-size: 12px;
  }
}

.azul {
  color: #0F2335;
}

.contenedor img {
  width: 100%;
  height: 200px;
  overflow: hidden;

}

.contenedor2 {
  overflow: hidden;
  width: 100%;
  height: 100%;



}


.contenedor2 img {
  width: 100%;
  height: 200px;
  overflow: hidden;

}

.aaa {
  margin-left: 22px;
  border: 2px solid #ed701c;
  height: 0px;
  width: 235px;
  float: left;
}

.lineaOrange {

  border: 1px solid #ed701c;
  height: 0px;
  width: 75%;
  float: left;
}




.centrado {

  color: white;
  font-size: 20px;

  background-color: black;
  padding: 6px;
}


.centrado2 {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;


}


.centrado6 {
  position: absolute;
  top: 32%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;

}









.centrado4 {

  text-align: center;
  color: white;


}


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-dialog-container {

  padding: 0px 0px !important;
}

@media(max-width:600px) {
  .mat-dialog-container {
    padding: 24px 0px;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ED701C;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}


.flex-centrar {
  display: flex;
  padding: 0px;
  justify-content: center;
}

.contenedor-tabla {

  width: 95% !important;
  overflow-x: auto;

}



.header-tr th {
  font-family: Montserrat;
  font-size: 15px;
}

@media(max-width:670px) {
  .header-tr th {
    font-size: 14px;
  }
}

.header-tr th,
.body-td td {
  width: 120px;
}

.body-td td {
  font-family: Lato;
  font-size: 16px;
}

@media(max-width:917px) {
  .body-td td {
    font-family: Lato;
    font-size: 14px;
  }
}

.body-td td {
  border-bottom: 1px solid #0F2335 !important;
  background-color: #EAEAEA !important;
}


th {
  background: #0F2335;
  color: #F7DBA7;
}

.inputs-contenedor {
  padding: 1px;
  flex-grow: 12;
}

.inputs-contenedor2 {
  flex-grow: 12;
  width: 464px;
  height: 36px;
  margin-bottom: 48px;
}

.button-contenedor {
  flex-grow: 1;
}

.margen-top {
  margin-top: 20px;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.buscadores,
.buscadores-2 {
  width: 80%;
  border-radius: 18px;
  font-family: Montserrat;
  font-weight: bold;
  background-color: #EAEAEA;
  border: 0px;
  padding: 5px;
  padding-left: 13px;
  color: #AAAAAA;
  background-repeat: no-repeat;
  background-position: 10px center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-size: 18px 18px;
  padding-left: 40px !important;
  cursor: pointer;
  color: #aaaaaa;
  font-size: 13px;
  font-family: Montserrat;
}

.buscadores-2 {
  font-size: 14px !important;
  font-family: Montserrat;
}

.buscadores::placeholder {
  color: #aaaaaa;
}

@media(max-width:790px) {

  .img11 {
    width: 100%;
    height: 11vw !important;
  }

  .img112 {
    width: 100%;
    height: 11vw !important;
  }

  .filtroNaranja1 {
    width: 50%;
    height: 11vw;
  }

  .nombre1 {
    font-size: 17px !important;
    margin-top: 8px !important;
  }

  .contenedor-flex {
    flex-wrap: wrap;
  }




}



.contenedor-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contenedor-flex2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  height: 100px
}

.contenedor-buscador {
  width: 100%;
}


@media(max-width:569px) {
  .tabla {
    width: 100%;

  }

  .esconder {
    overflow-x: scroll;
  }

}

.centrarTexto {
  text-align: center;
}


@media(max-width:540px) {

  .action_button {
    padding: 7px 17px;
  }
}

.action_button:hover {

  background: white;
  color: #0F2335;
  border: 1px solid #0F2335;
}
